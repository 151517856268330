import { Injectable } from '@angular/core';
import { ProductFamily, ProductFamilyListResponse, ProductFamilyResponse } from 'src/app/models/portal/products-family';
import { ResponseDTO } from 'src/app/models/ResponseDTO';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductFamilyService {

  constructor(private api: ApiService) { }

  public async getList() {
    const res = await this.api.get<ProductFamilyListResponse>('API_BASE', 'ProductFamilies');
    if (res.code === 200) {
      return res.productFamilies;
    } else {
      throw new Error(res.message);
    }
  }

  public async getById(id: number) {
    const res = await this.api.get<ProductFamilyResponse>('API_BASE', 'ProductFamilies/' + id);
    if (res.code === 200) {
      return res.productFamily;
    } else {
      throw new Error(res.message);
    }
  }

  public async create(family: ProductFamily) {
    const res = await this.api.post<ResponseDTO>('API_BASE', family, 'ProductFamilies');
    if (res.code === 200) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }

  public async update(id: number, family: ProductFamily) {
    const res = await this.api.put<ResponseDTO>('API_BASE', family, 'ProductFamilies/' + id);
    if (res.code === 200) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }

  public async delete(id: number) {
    const res = await this.api.delete('API_BASE', 'ProductFamilies/' + id);
    if (res.code === 200) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }

  public async reactivate(id: number) {
    const res = await this.api.put<ResponseDTO>('API_BASE', {}, 'ProductFamilies/enabled/' + id);
    if (res.code === 200) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }

  public async getByHeaderId(id: number) {
    const res = await this.api.get<ProductFamilyListResponse>('API_BASE', 'ProductFamilies/header/' + id);
    if (res.code === 200) {
      return res.productFamilies;
    } else {
      throw new Error(res.message);
    }
  }
}

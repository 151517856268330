import { CountryDTO } from './countryDTO';
import { ResponseDTO } from './ResponseDTO';
export class DiscountDTO {
    public listPrice: number;
    public discountID: number;
    public partNumber: string;
    public clientID: number;
    public discountPercent: number;
    public country: CountryDTO = new CountryDTO();
}
export class DiscountResponse extends ResponseDTO {
    discounts: DiscountDTO[];
}

export class CreateDiscountDTO {
    public myID: number;
    public clientID: number;
    public discounts: DiscountDTO[];
    public deleteDiscountsIds: number[];
}

import { BasicRequest } from './basicRequest';
export class EditBasicAccountDataDTO {
    public myID: number;
    constructor(
        public accountID: number,
        public eMail: string,
        public firstName: string,
        public lastName: string,
        public phoneNumber?: string,
        public sales_manager?:number
        ) 
    {  }
}